.gal_body {
  margin: 0;
  width: 100%;
  min-height: 90vh;
  padding: 10%;
  display: grid;
  place-content: center;
  background: #83af9b;

  h1 {
    font-size: 2.5rem;
    color: #fff;
    text-align: center;
    margin-bottom: 2rem;
  }
}

.gallery_images {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
  width: 100%;
}

.gallery_images img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

@media screen and (max-width: 768px) {
  .gal_body {
    h1 {
      font-size: 2rem;
    }
  }
  .gallery_images {
    grid-template-columns: repeat(1, 1fr);
  }
}
