.logo {
  width: 200px;
  height: 200px;
  margin: 0;

  img {
    width: 100%;
    height: 100%;
  }
}

nav {
  width: 100%;
  height: 10dvh;
  background-color: #f1f1f1;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;

  ul {
    display: flex;
    justify-content: space-evenly;
    gap: 30px;
    width: 70%;
    align-items: center;
    list-style: none;
    padding: 0;
    margin: 0;

    li {
      text-align: center;

      a::before {
        content: "";
        position: absolute;
        bottom: -2px;
        left: 0;
        width: 0;
        height: 2px;
        background: linear-gradient(
          90deg,
          #254d8e,
          #003f1d,
          #ed8100
        ); /* Gradient color */
        transition: width 0.3s ease;
      }

      a:hover::before {
        width: 100%;
      }

      a:active {
        color: linear-gradient(90deg, #254d8e, #003f1d, #ed8100);
      }

      a {
        display: flex;
        flex-direction: column;
        position: relative;

        p {
          margin: 0;
        }
      }
    }
  }
}

@media screen and (min-width: 991px) {
  .navv {
    justify-content: flex-end;
  }
}

@media screen and (max-width: 991px) {
  .navv {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;

    ul {
      display: flex;
      flex-direction: column;
      row-gap: 30px;
      list-style: none;

      li {
        a {
          display: flex;
          gap: 30px;
          flex-direction: row;
        }

        a:active {
          color: blue;
        }
      }
    }
  }
}

.navbar-social-logo {
  display: flex;
  flex-direction: row;
  gap: 20px;
  align-items: center;
}

.svg-inline--fa {
  font-size: 1.5rem;
}

.navbar-toggler {
  min-height: 40px;
}
