.landing_wrapper {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: calc(100vh - var(--navbar-height));
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background: rgb(2, 0, 36);
  background: linear-gradient(
    90deg,
    rgba(2, 0, 36, 1) 0%,
    rgba(0, 200, 230, 1) 100%
  );
  color: #fff;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  /* Other styles for your content */
  h1,
  button {
    position: relative;
    /* Ensure content is above the blurred background */
    z-index: 1;
  }

  .hero-left,
  .hero-right {
    width: 50%;
    text-align: center;

    a {
      cursor: pointer;
    }

    button {
      cursor: pointer;
      padding: 20px;
      color: white;
      border: none;
      border-radius: 15px;
      background: rgb(216, 139, 251);
      background: linear-gradient(
        90deg,
        rgba(216, 139, 251, 1) 6%,
        rgba(65, 238, 255, 1) 100%
      );
      font-size: 1.2em;
      font-weight: bold;
    }

    button:hover {
      background: linear-gradient(
        90deg,
        rgba(65, 238, 255, 1) 15%,
        rgba(216, 139, 251, 1) 100%
      );
    }
  }

  .hero-right img {
    width: 80%;
    height: 60%;
  }
}

.hero-left h1 {
  width: 80%;
  margin: 0 auto 60px;
}

@media screen and (max-width: 768px) {
  .landing_wrapper {
    flex-direction: column;

    .hero-left {
      position: relative;
      z-index: 10;
    }

    .hero-right {
      position: absolute;
      filter: opacity(0.5);
      z-index: 0;

      img {
        width: 200%;
        height: 200%;
      }
    }
  }
}
