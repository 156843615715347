.contact {
  padding: 2rem 6rem;
  margin: 0 auto;
  width: 60vw;

  h1 {
    font-size: 3rem;
  }

  .contact-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 0 auto;
    color: #000;
    gap: 30px;
  }
}
